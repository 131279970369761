var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

(function (doc, win) {
  var donutty,
      namespace = "http://www.w3.org/2000/svg";

  function isDefined(input) {
    return typeof input !== "undefined";
  }

  function float(input) {
    return parseFloat(input, 10);
  }

  function truth(input) {
    return isDefined(input) && (input === true || input === "true");
  }

  donutty = win.Donutty = function (el, options) {
    if (el && typeof el === "string") {
      (this || _global).$wrapper = doc.querySelectorAll(el)[0];
    } else if (el instanceof window.HTMLElement) {
      (this || _global).$wrapper = el;
    } else {
      (this || _global).$wrapper = doc.body;
      options = el;
    }

    if (!(this || _global).$wrapper) {
      return this || _global;
    }

    if (!isDefined(options)) {
      options = this.getOptionsFromTag();
    }

    (this || _global).id = Math.random().toString(36).substr(2, 5);
    (this || _global).state = {};
    (this || _global).options = options || {};
    (this || _global).options.min = isDefined((this || _global).options.min) ? float((this || _global).options.min) : 0;
    (this || _global).options.max = isDefined((this || _global).options.max) ? float((this || _global).options.max) : 100;
    (this || _global).options.value = isDefined((this || _global).options.value) ? float((this || _global).options.value) : 50;
    (this || _global).options.round = isDefined((this || _global).options.round) ? truth((this || _global).options.round) : true;
    (this || _global).options.circle = isDefined((this || _global).options.circle) ? truth((this || _global).options.circle) : true;
    (this || _global).options.padding = isDefined((this || _global).options.padding) ? float((this || _global).options.padding) : 4;
    (this || _global).options.radius = float((this || _global).options.radius) || 50;
    (this || _global).options.thickness = float((this || _global).options.thickness) || 10;
    (this || _global).options.bg = (this || _global).options.bg || "rgba(70, 130, 180, 0.15)";
    (this || _global).options.color = (this || _global).options.color || "mediumslateblue";
    (this || _global).options.transition = (this || _global).options.transition || "all 1.2s cubic-bezier(0.57, 0.13, 0.18, 0.98)";
    (this || _global).options.text = isDefined((this || _global).options.text) ? (this || _global).options.text : false;
    (this || _global).options.title = isDefined((this || _global).options.title) ? (this || _global).options.title : function () {
      return "Donut Chart Graphic";
    };
    (this || _global).options.desc = isDefined((this || _global).options.desc) ? (this || _global).options.desc : function (v) {
      return "A donut chart ranging from " + v.min + " to " + v.max + " with a current value of " + v.value + ".";
    };
    (this || _global).options.dir = isDefined((this || _global).options.dir) ? (this || _global).options.dir : false;
    (this || _global).options.anchor = isDefined((this || _global).options.anchor) && (this || _global).options.anchor === "top" ? "top" : "bottom";

    if (!(this || _global).options.dir) {
      (this || _global).options.dir = (this || _global).$wrapper.dir;
    }

    if (!(this || _global).options.dir) {
      (this || _global).options.dir = document.body.parentElement.dir;
    }

    this.init();
    return this || _global;
  };

  donutty.prototype.getOptionsFromTag = function () {
    return JSON.parse(JSON.stringify((this || _global).$wrapper.dataset));
  };

  donutty.prototype.init = function () {
    (this || _global).$wrapper.donutty = this || _global;
    var values; // create the state object from the options,
    // and then get the dash values for use in element creation

    this.createState();
    values = this.getDashValues();
    this.createSvg();
    this.createBg(values);
    this.createDonut(values);
    this.createText();
    this.createAccessibility();
    this.insertFragments(values);
    return this || _global;
  };

  donutty.prototype.createState = function () {
    (this || _global).state.min = (this || _global).options.min;
    (this || _global).state.max = (this || _global).options.max;
    (this || _global).state.value = (this || _global).options.value;
    (this || _global).state.bg = (this || _global).options.bg;
    (this || _global).state.color = (this || _global).options.color;
    return this || _global;
  };

  donutty.prototype.createText = function () {
    if (typeof (this || _global).options.text === "function") {
      (this || _global).$text = doc.createElement("span");

      (this || _global).$text.setAttribute("class", "donut-text");

      (this || _global).$text.style.opacity = 0;
      this.updateText();
    }

    return this || _global;
  };

  donutty.prototype.createAccessibility = function () {
    (this || _global).$title = doc.createElementNS(namespace, "title");

    (this || _global).$title.setAttribute("id", "chartTitle-" + (this || _global).id);

    (this || _global).$title.setAttribute("class", "donut-title");

    (this || _global).$desc = doc.createElementNS(namespace, "desc");

    (this || _global).$desc.setAttribute("id", "chartDesc-" + (this || _global).id);

    (this || _global).$desc.setAttribute("class", "donut-desc");

    this.updateAccessibility();
    return this || _global;
  };

  donutty.prototype.createBg = function (values) {
    (this || _global).$bg = doc.createElementNS(namespace, "circle");

    (this || _global).$bg.setAttribute("cx", "50%");

    (this || _global).$bg.setAttribute("cy", "50%");

    (this || _global).$bg.setAttribute("r", (this || _global).options.radius);

    (this || _global).$bg.setAttribute("fill", "transparent");

    (this || _global).$bg.setAttribute("stroke", (this || _global).state.bg);

    (this || _global).$bg.setAttribute("stroke-width", (this || _global).options.thickness + (this || _global).options.padding);

    (this || _global).$bg.setAttribute("stroke-dasharray", values.full * values.multiplier);

    (this || _global).$bg.setAttribute("class", "donut-bg");

    if ((this || _global).options.round) {
      (this || _global).$bg.setAttribute("stroke-linecap", "round");
    }

    return this || _global;
  };

  donutty.prototype.createDonut = function (values) {
    (this || _global).$donut = doc.createElementNS(namespace, "circle");

    (this || _global).$donut.setAttribute("fill", "transparent");

    (this || _global).$donut.setAttribute("cx", "50%");

    (this || _global).$donut.setAttribute("cy", "50%");

    (this || _global).$donut.setAttribute("r", (this || _global).options.radius);

    (this || _global).$donut.setAttribute("stroke", (this || _global).state.color);

    (this || _global).$donut.setAttribute("stroke-width", (this || _global).options.thickness);

    (this || _global).$donut.setAttribute("stroke-dashoffset", values.full);

    (this || _global).$donut.setAttribute("stroke-dasharray", values.full);

    (this || _global).$donut.setAttribute("class", "donut-fill");

    (this || _global).$donut.style.opacity = 0;

    if ((this || _global).options.round) {
      (this || _global).$donut.setAttribute("stroke-linecap", "round");
    }

    return this || _global;
  };

  donutty.prototype.createSvg = function () {
    var viewbox = (this || _global).options.radius * 2 + (this || _global).options.thickness + 1,
        rotateExtra = (this || _global).options.round ? (this || _global).options.thickness / 3 : 0,
        rotate = (this || _global).options.circle ? 90 + rotateExtra : -225,
        scaleX = (this || _global).options.dir === "rtl" ? "-1" : "1",
        scaleY = (this || _global).options.anchor === "top" ? "-1" : "1",
        scale = scaleX + "," + scaleY;

    if ((this || _global).options.padding >= 0) {
      viewbox += (this || _global).options.padding;
    }

    (this || _global).$html = doc.createDocumentFragment();
    (this || _global).$svg = doc.createElementNS(namespace, "svg");

    (this || _global).$svg.setAttribute("xmlns", namespace);

    (this || _global).$svg.setAttribute("viewbox", "0 0 " + viewbox + " " + viewbox);

    (this || _global).$svg.setAttribute("width", "100%");

    (this || _global).$svg.setAttribute("style", "transform: scale(" + scale + ") rotate(" + rotate + "deg)");

    (this || _global).$svg.setAttribute("preserveAspectRatio", "xMidYMid meet");

    (this || _global).$svg.setAttribute("class", "donut");

    (this || _global).$svg.setAttribute("role", "img");

    (this || _global).$svg.setAttribute("aria-labelledby", "chartTitle-" + (this || _global).id + " chartDesc-" + (this || _global).id);

    (this || _global).$svg.setAttribute("role", "img");

    return this || _global;
  };

  donutty.prototype.insertFragments = function (values) {
    (this || _global).$svg.appendChild((this || _global).$title);

    (this || _global).$svg.appendChild((this || _global).$desc);

    (this || _global).$svg.appendChild((this || _global).$bg);

    (this || _global).$svg.appendChild((this || _global).$donut);

    (this || _global).$html.appendChild((this || _global).$svg);

    if ((this || _global).$text) {
      (this || _global).$html.appendChild((this || _global).$text);
    }

    (this || _global).$wrapper.appendChild((this || _global).$html); // because of a strange bug in browsers not updating
    // the "preserveAspectRatio" setting when applied programmatically,
    // we need to essentially delete the DOM fragment, and then
    // set the innerHTML of the parent so that it updates in browser.


    (this || _global).$wrapper.innerHTML = (this || _global).$wrapper.innerHTML; // and because we just destroyed the DOM fragment and all
    // the references to it, we now set all those references again.

    (this || _global).$svg = (this || _global).$wrapper.querySelector(".donut");
    (this || _global).$bg = (this || _global).$wrapper.querySelector(".donut-bg");
    (this || _global).$donut = (this || _global).$wrapper.querySelector(".donut-fill");
    (this || _global).$title = (this || _global).$wrapper.querySelector(".donut-title");
    (this || _global).$desc = (this || _global).$wrapper.querySelector(".donut-desc");

    if ((this || _global).$text) {
      (this || _global).$text = (this || _global).$wrapper.querySelector(".donut-text");
    } // now the references are re-set, we can go 
    // ahead and animate the element again.


    this.animate(values.fill, values.full);
  };

  donutty.prototype.getDashValues = function () {
    var circumference, percentageFilled, absoluteFilled, multiplier;
    multiplier = (this || _global).options.circle ? 1 : 0.75;
    circumference = 2 * Math.PI * (this || _global).options.radius;
    percentageFilled = ((this || _global).state.value - (this || _global).state.min) / ((this || _global).state.max - (this || _global).state.min) * 100;
    absoluteFilled = circumference - circumference * multiplier / 100 * percentageFilled;

    if ((this || _global).options.round && (this || _global).options.circle && percentageFilled < 100 && absoluteFilled < (this || _global).options.thickness) {
      // when in circle mode, if the linecaps are "round"
      // then the circle would look complete if it is actually
      // only ~97% complete, this is because the linecaps
      // overhang the stroke.
      absoluteFilled = (this || _global).options.thickness;
    }

    return {
      fill: absoluteFilled,
      full: circumference,
      multiplier: multiplier
    };
  };

  donutty.prototype.animate = function (fill, full) {
    var _this = this || _global; // ensure the transition property is applied before
    // the actual properties are set, so that browser renders
    // the transition


    (this || _global).$bg.style.transition = (this || _global).options.transition;
    (this || _global).$donut.style.transition = (this || _global).options.transition;

    if ((this || _global).$text) {
      (this || _global).$text.style.transition = (this || _global).options.transition;
    } // use a short timeout (~60fps) to simulate a new
    // animation frame (not using rAF due to ie9 problems)


    window.setTimeout(function () {
      _this.$bg.setAttribute("stroke", _this.state.bg);

      _this.$bg.style.opacity = 1;

      _this.$donut.setAttribute("stroke-dashoffset", fill);

      _this.$donut.setAttribute("stroke-dasharray", full);

      _this.$donut.setAttribute("stroke", _this.state.color);

      _this.$donut.style.opacity = 1;

      if (_this.$text) {
        _this.$text.style.opacity = 1;
      }
    }, 16);
  };
  /**
   * use the current state to set the text inside
   * the text element (only if option is provided);
   * @return {object} the donut instance
   */


  donutty.prototype.updateText = function () {
    if (typeof (this || _global).options.text === "function") {
      (this || _global).$text.innerHTML = (this || _global).options.text((this || _global).state);
    }

    return this || _global;
  };
  /**
   * use the current state to set the text inside
   * the title and description for accessibility;
   * @return {object} the donut instance
   */


  donutty.prototype.updateAccessibility = function () {
    if (typeof (this || _global).options.title === "function") {
      (this || _global).$title.innerHTML = (this || _global).options.title((this || _global).state);
    } else {
      (this || _global).$title.innerHTML = (this || _global).options.title;
    }

    if (typeof (this || _global).options.desc === "function") {
      (this || _global).$desc.innerHTML = (this || _global).options.desc((this || _global).state);
    } else {
      (this || _global).$desc.innerHTML = (this || _global).options.desc;
    }

    return this || _global;
  };
  /**
   * set an individual state property for the chart
   * @param  {string}         prop the property to set
   * @param  {string/number}  val  the value of the given property
   * @return {object}              the donut instance
   * @chainable
   */


  donutty.prototype.set = function (prop, val) {
    var values;

    if (isDefined(prop) && isDefined(val)) {
      (this || _global).state[prop] = val;
      values = this.getDashValues();
      this.updateText();
      this.updateAccessibility();
      this.animate(values.fill, values.full);
    }

    return this || _global;
  };
  /**
   * set multiple state properties with an object
   * @param  {object} newState a map of properties to set
   * @return {object}          the donut instance
   * @chainable
   */


  donutty.prototype.setState = function (newState) {
    var values;

    if (isDefined(newState.value)) {
      (this || _global).state.value = newState.value;
    }

    if (isDefined(newState.min)) {
      (this || _global).state.min = newState.min;
    }

    if (isDefined(newState.max)) {
      (this || _global).state.max = newState.max;
    }

    if (isDefined(newState.bg)) {
      (this || _global).state.bg = newState.bg;
    }

    if (isDefined(newState.color)) {
      (this || _global).state.color = newState.color;
    }

    values = this.getDashValues();
    this.updateText();
    this.updateAccessibility();
    this.animate(values.fill, values.full);
    return this || _global;
  };
})(document, window);

export default {};